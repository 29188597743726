import { Flex, FlexProps, Spinner } from '@chakra-ui/core'
import * as React from 'react'
import { ChevronRight } from 'react-feather'
import { Text } from '../../typography'

type MenuListItemProps = FlexProps & {
  title: string
  isLoading?: boolean
}

const MenuListItem: React.FC<MenuListItemProps> = ({ title, isLoading, ...rest }) => {
  return (
    <Flex {...rest}>
      <Text>{title}</Text>
      {isLoading ? <Spinner size="md" color="gray.500" /> : <ChevronRight size={24} />}
    </Flex>
  )
}

export default MenuListItem

MenuListItem.defaultProps = {
  width: '100%',
  p: 4,
  justifyContent: 'space-between',
  borderBottomWidth: 1,
  align: 'center'
}
