
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Morph": [
      "Analytics",
      "PaginatedBookCollection",
      "PaginatedBooks",
      "PageInfo",
      "CheckoutSessionPayload",
      "Price",
      "PaymentMethod",
      "SubscriptionPayload",
      "PaginatedVideoCollection",
      "PaginatedVideos",
      "UsersPermissionsMe",
      "UsersPermissionsMeRole",
      "UsersPermissionsLoginPayload",
      "UserPermissionsPasswordPayload",
      "ChangePasswordPayload",
      "BookCollections",
      "BookCollectionsConnection",
      "BookCollectionsAggregator",
      "BookCollectionsGroupBy",
      "BookCollectionsConnectionId",
      "BookCollectionsConnectionCreated_at",
      "BookCollectionsConnectionUpdated_at",
      "BookCollectionsConnectionName",
      "createBookCollectionPayload",
      "updateBookCollectionPayload",
      "deleteBookCollectionPayload",
      "Books",
      "BooksConnection",
      "BooksAggregator",
      "BooksGroupBy",
      "BooksConnectionId",
      "BooksConnectionCreated_at",
      "BooksConnectionUpdated_at",
      "BooksConnectionTitle",
      "BooksConnectionAuthor",
      "BooksConnectionDescription",
      "BooksConnectionBuyNowLink",
      "BooksConnectionPreviewLink",
      "BooksConnectionCover",
      "createBookPayload",
      "updateBookPayload",
      "deleteBookPayload",
      "OnlineChurchPoster",
      "updateOnlineChurchPosterPayload",
      "deleteOnlineChurchPosterPayload",
      "OnlineChurch",
      "OnlineChurchConnection",
      "OnlineChurchAggregator",
      "OnlineChurchGroupBy",
      "OnlineChurchConnectionId",
      "OnlineChurchConnectionCreated_at",
      "OnlineChurchConnectionUpdated_at",
      "OnlineChurchConnectionTitle",
      "OnlineChurchConnectionDateTime",
      "OnlineChurchConnectionLink",
      "createOnlineChurchPayload",
      "updateOnlineChurchPayload",
      "deleteOnlineChurchPayload",
      "PropheticChannel",
      "updatePropheticChannelPayload",
      "deletePropheticChannelPayload",
      "Quotes",
      "QuotesConnection",
      "QuotesAggregator",
      "QuotesGroupBy",
      "QuotesConnectionId",
      "QuotesConnectionCreated_at",
      "QuotesConnectionUpdated_at",
      "QuotesConnectionDescription",
      "QuotesConnectionDate",
      "createQuotePayload",
      "updateQuotePayload",
      "deleteQuotePayload",
      "Subscription",
      "SubscriptionConnection",
      "SubscriptionAggregator",
      "SubscriptionGroupBy",
      "SubscriptionConnectionId",
      "SubscriptionConnectionCreated_at",
      "SubscriptionConnectionUpdated_at",
      "SubscriptionConnectionStripeId",
      "SubscriptionConnectionCustomer",
      "SubscriptionConnectionCurrentPeriodEnd",
      "SubscriptionConnectionCurrentPeriodStart",
      "SubscriptionConnectionName",
      "SubscriptionConnectionPaymentMethod",
      "createSubscriptionPayload",
      "updateSubscriptionPayload",
      "deleteSubscriptionPayload",
      "VideoCollections",
      "VideoCollectionsConnection",
      "VideoCollectionsAggregator",
      "VideoCollectionsGroupBy",
      "VideoCollectionsConnectionId",
      "VideoCollectionsConnectionCreated_at",
      "VideoCollectionsConnectionUpdated_at",
      "VideoCollectionsConnectionName",
      "createVideoCollectionPayload",
      "updateVideoCollectionPayload",
      "deleteVideoCollectionPayload",
      "Videos",
      "VideosConnection",
      "VideosAggregator",
      "VideosGroupBy",
      "VideosConnectionId",
      "VideosConnectionCreated_at",
      "VideosConnectionUpdated_at",
      "VideosConnectionVideo",
      "VideosConnectionTitle",
      "createVideoPayload",
      "updateVideoPayload",
      "deleteVideoPayload",
      "UploadFile",
      "UploadFileConnection",
      "UploadFileAggregator",
      "UploadFileAggregatorSum",
      "UploadFileAggregatorAvg",
      "UploadFileAggregatorMin",
      "UploadFileAggregatorMax",
      "UploadFileGroupBy",
      "UploadFileConnectionId",
      "UploadFileConnectionCreated_at",
      "UploadFileConnectionUpdated_at",
      "UploadFileConnectionName",
      "UploadFileConnectionAlternativeText",
      "UploadFileConnectionCaption",
      "UploadFileConnectionWidth",
      "UploadFileConnectionHeight",
      "UploadFileConnectionFormats",
      "UploadFileConnectionHash",
      "UploadFileConnectionExt",
      "UploadFileConnectionMime",
      "UploadFileConnectionSize",
      "UploadFileConnectionUrl",
      "UploadFileConnectionPreviewUrl",
      "UploadFileConnectionProvider",
      "UploadFileConnectionProvider_metadata",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsRoleConnection",
      "UsersPermissionsRoleAggregator",
      "UsersPermissionsRoleGroupBy",
      "UsersPermissionsRoleConnectionId",
      "UsersPermissionsRoleConnectionName",
      "UsersPermissionsRoleConnectionDescription",
      "UsersPermissionsRoleConnectionType",
      "createRolePayload",
      "updateRolePayload",
      "deleteRolePayload",
      "UsersPermissionsUser",
      "UsersPermissionsUserConnection",
      "UsersPermissionsUserAggregator",
      "UsersPermissionsUserGroupBy",
      "UsersPermissionsUserConnectionId",
      "UsersPermissionsUserConnectionCreated_at",
      "UsersPermissionsUserConnectionUpdated_at",
      "UsersPermissionsUserConnectionUsername",
      "UsersPermissionsUserConnectionEmail",
      "UsersPermissionsUserConnectionProvider",
      "UsersPermissionsUserConnectionConfirmed",
      "UsersPermissionsUserConnectionBlocked",
      "UsersPermissionsUserConnectionRole",
      "UsersPermissionsUserConnectionPhoneNumber",
      "UsersPermissionsUserConnectionProfilePicture",
      "UsersPermissionsUserConnectionIsSubscribed",
      "UsersPermissionsUserConnectionFirstName",
      "UsersPermissionsUserConnectionLastName",
      "UsersPermissionsUserConnectionStripeCustomerId",
      "createUserPayload",
      "updateUserPayload",
      "deleteUserPayload",
      "ComponentPaymentMethodPaymentMethod"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Morph": [
      "Analytics",
      "PaginatedBookCollection",
      "PaginatedBooks",
      "PageInfo",
      "CheckoutSessionPayload",
      "Price",
      "PaymentMethod",
      "SubscriptionPayload",
      "PaginatedVideoCollection",
      "PaginatedVideos",
      "UsersPermissionsMe",
      "UsersPermissionsMeRole",
      "UsersPermissionsLoginPayload",
      "UserPermissionsPasswordPayload",
      "ChangePasswordPayload",
      "BookCollections",
      "BookCollectionsConnection",
      "BookCollectionsAggregator",
      "BookCollectionsGroupBy",
      "BookCollectionsConnectionId",
      "BookCollectionsConnectionCreated_at",
      "BookCollectionsConnectionUpdated_at",
      "BookCollectionsConnectionName",
      "createBookCollectionPayload",
      "updateBookCollectionPayload",
      "deleteBookCollectionPayload",
      "Books",
      "BooksConnection",
      "BooksAggregator",
      "BooksGroupBy",
      "BooksConnectionId",
      "BooksConnectionCreated_at",
      "BooksConnectionUpdated_at",
      "BooksConnectionTitle",
      "BooksConnectionAuthor",
      "BooksConnectionDescription",
      "BooksConnectionBuyNowLink",
      "BooksConnectionPreviewLink",
      "BooksConnectionCover",
      "createBookPayload",
      "updateBookPayload",
      "deleteBookPayload",
      "OnlineChurchPoster",
      "updateOnlineChurchPosterPayload",
      "deleteOnlineChurchPosterPayload",
      "OnlineChurch",
      "OnlineChurchConnection",
      "OnlineChurchAggregator",
      "OnlineChurchGroupBy",
      "OnlineChurchConnectionId",
      "OnlineChurchConnectionCreated_at",
      "OnlineChurchConnectionUpdated_at",
      "OnlineChurchConnectionTitle",
      "OnlineChurchConnectionDateTime",
      "OnlineChurchConnectionLink",
      "createOnlineChurchPayload",
      "updateOnlineChurchPayload",
      "deleteOnlineChurchPayload",
      "PropheticChannel",
      "updatePropheticChannelPayload",
      "deletePropheticChannelPayload",
      "Quotes",
      "QuotesConnection",
      "QuotesAggregator",
      "QuotesGroupBy",
      "QuotesConnectionId",
      "QuotesConnectionCreated_at",
      "QuotesConnectionUpdated_at",
      "QuotesConnectionDescription",
      "QuotesConnectionDate",
      "createQuotePayload",
      "updateQuotePayload",
      "deleteQuotePayload",
      "Subscription",
      "SubscriptionConnection",
      "SubscriptionAggregator",
      "SubscriptionGroupBy",
      "SubscriptionConnectionId",
      "SubscriptionConnectionCreated_at",
      "SubscriptionConnectionUpdated_at",
      "SubscriptionConnectionStripeId",
      "SubscriptionConnectionCustomer",
      "SubscriptionConnectionCurrentPeriodEnd",
      "SubscriptionConnectionCurrentPeriodStart",
      "SubscriptionConnectionName",
      "SubscriptionConnectionPaymentMethod",
      "createSubscriptionPayload",
      "updateSubscriptionPayload",
      "deleteSubscriptionPayload",
      "VideoCollections",
      "VideoCollectionsConnection",
      "VideoCollectionsAggregator",
      "VideoCollectionsGroupBy",
      "VideoCollectionsConnectionId",
      "VideoCollectionsConnectionCreated_at",
      "VideoCollectionsConnectionUpdated_at",
      "VideoCollectionsConnectionName",
      "createVideoCollectionPayload",
      "updateVideoCollectionPayload",
      "deleteVideoCollectionPayload",
      "Videos",
      "VideosConnection",
      "VideosAggregator",
      "VideosGroupBy",
      "VideosConnectionId",
      "VideosConnectionCreated_at",
      "VideosConnectionUpdated_at",
      "VideosConnectionVideo",
      "VideosConnectionTitle",
      "createVideoPayload",
      "updateVideoPayload",
      "deleteVideoPayload",
      "UploadFile",
      "UploadFileConnection",
      "UploadFileAggregator",
      "UploadFileAggregatorSum",
      "UploadFileAggregatorAvg",
      "UploadFileAggregatorMin",
      "UploadFileAggregatorMax",
      "UploadFileGroupBy",
      "UploadFileConnectionId",
      "UploadFileConnectionCreated_at",
      "UploadFileConnectionUpdated_at",
      "UploadFileConnectionName",
      "UploadFileConnectionAlternativeText",
      "UploadFileConnectionCaption",
      "UploadFileConnectionWidth",
      "UploadFileConnectionHeight",
      "UploadFileConnectionFormats",
      "UploadFileConnectionHash",
      "UploadFileConnectionExt",
      "UploadFileConnectionMime",
      "UploadFileConnectionSize",
      "UploadFileConnectionUrl",
      "UploadFileConnectionPreviewUrl",
      "UploadFileConnectionProvider",
      "UploadFileConnectionProvider_metadata",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsRoleConnection",
      "UsersPermissionsRoleAggregator",
      "UsersPermissionsRoleGroupBy",
      "UsersPermissionsRoleConnectionId",
      "UsersPermissionsRoleConnectionName",
      "UsersPermissionsRoleConnectionDescription",
      "UsersPermissionsRoleConnectionType",
      "createRolePayload",
      "updateRolePayload",
      "deleteRolePayload",
      "UsersPermissionsUser",
      "UsersPermissionsUserConnection",
      "UsersPermissionsUserAggregator",
      "UsersPermissionsUserGroupBy",
      "UsersPermissionsUserConnectionId",
      "UsersPermissionsUserConnectionCreated_at",
      "UsersPermissionsUserConnectionUpdated_at",
      "UsersPermissionsUserConnectionUsername",
      "UsersPermissionsUserConnectionEmail",
      "UsersPermissionsUserConnectionProvider",
      "UsersPermissionsUserConnectionConfirmed",
      "UsersPermissionsUserConnectionBlocked",
      "UsersPermissionsUserConnectionRole",
      "UsersPermissionsUserConnectionPhoneNumber",
      "UsersPermissionsUserConnectionProfilePicture",
      "UsersPermissionsUserConnectionIsSubscribed",
      "UsersPermissionsUserConnectionFirstName",
      "UsersPermissionsUserConnectionLastName",
      "UsersPermissionsUserConnectionStripeCustomerId",
      "createUserPayload",
      "updateUserPayload",
      "deleteUserPayload",
      "ComponentPaymentMethodPaymentMethod"
    ]
  }
};
      export default result;
    