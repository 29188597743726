import { Flex } from '@chakra-ui/core'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { Check } from 'react-feather'
import { Text } from '../../typography'
import { InnerCircle, OuterCircle, SpacerLine, Square } from './styles'

type StepperProps = {
  activeStep: number
  stepInfo?: string[]
}

const AnimatedCheck = motion.custom(Check)

const Stepper: React.FC<StepperProps> = ({ activeStep, stepInfo, children }) => {
  const childArr = React.Children.toArray(children)

  const stepCount = childArr.length

  const spacerRef = React.useRef<HTMLDivElement>(null)

  const [spacerWidth, setSpacerWidth] = React.useState(0)

  React.useEffect(() => {
    if (spacerRef && spacerRef.current) {
      setSpacerWidth(spacerRef.current.offsetWidth)
    }
  }, [])

  if (stepInfo && stepInfo.length > 0) {
    if (stepInfo.length !== stepCount) {
      throw Error("Stepper Error: Step info and number of steps don't match. Please update.")
    }
  }

  return (
    <React.Fragment>
      <Flex
        p={4}
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent={stepCount === 1 ? 'flex-end' : 'space-between'}
      >
        {childArr.map((_, i) => {
          const isCompletedStep = i < activeStep
          const isLastStep = i === stepCount - 1
          const isCurrentStep = i === activeStep
          return (
            <React.Fragment key={i}>
              {isCompletedStep ? (
                <Square>
                  <OuterCircle bg="green.500">
                    <AnimatedCheck
                      size={22}
                      color="white"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                    />
                  </OuterCircle>
                </Square>
              ) : (
                <Square>
                  <OuterCircle bg={isCurrentStep ? 'brand.500' : 'gray.200'}>
                    <InnerCircle bg={isCurrentStep ? 'gray.200' : 'gray.100'}>
                      <Text color={isCompletedStep ? 'white' : 'black'}>{i + 1}</Text>
                    </InnerCircle>
                  </OuterCircle>
                </Square>
              )}
              <AnimatePresence>
                {!isLastStep && (
                  <Flex pos="relative" flexDirection="column" justifyContent="center" flex={1}>
                    <SpacerLine ref={spacerRef} bg="gray.100" />
                    {isCompletedStep && (
                      <SpacerLine
                        bg="green.500"
                        className="test-spacer"
                        exit={{ width: 0 }}
                        initial={{ width: 0 }}
                        animate={{ width: spacerWidth }}
                      />
                    )}
                  </Flex>
                )}
              </AnimatePresence>
            </React.Fragment>
          )
        })}
      </Flex>
      <Flex px={4} flexDirection="row" justifyContent="space-around" width="100%">
        {stepInfo &&
          stepInfo.length > 0 &&
          stepInfo.map((step, i) => (
            <Flex
              key={i}
              flex={1}
              justifyContent="center"
              flexDirection="column"
              alignItems={
                i === 0 ? 'flex-start' : i === stepInfo.length - 1 ? 'flex-end' : 'center'
              }
            >
              <Text>{step}</Text>
            </Flex>
          ))}
      </Flex>
      {childArr[activeStep]}
    </React.Fragment>
  )
}

export default Stepper
