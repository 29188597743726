/**
 * @name useCheckout hook
 * @description Describe the purpose of this hook.
 * @example
 *   const ... = useCheckout()
 */

const useCheckout = () => {
  return {}
};

export default useCheckout;
