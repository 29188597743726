import { useLayoutEffect, useState } from 'react'

/**
 * @name useWindowSize hook
 * @description Gets the current size of the browser window.
 * @returns Object containing the window's width and height
 * @example
 *  const [width, height] = useWindowSize()
 */

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    const onResize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', onResize)

    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return size
}

export default useWindowSize
