import { Box, BoxProps } from '@chakra-ui/core'
import styled from '@emotion/styled'
import React, { FC, useRef } from 'react'
import { useMeasure } from '../../hooks'

interface ScrollViewProps extends BoxProps {}

const Wrapper = styled(Box)`
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1;
  flex-direction: column;
  overscroll-behavior: contain;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`

/**
 * @render react
 * @name ScrollView component
 * @description Renders its children in a scrollable.
 * @example
 * <ScrollView>
 *   <H1 />
 *   <Image />
 *   <Text />
 * </ScrollView>
 */

const ScrollView: FC<ScrollViewProps> = ({ children, ...rest }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const { height: wrapperHeight } = useMeasure(wrapperRef)
  const { height: contentHeight } = useMeasure(contentRef)

  const hasContentOverflow = contentHeight > wrapperHeight

  return (
    <Wrapper
      {...rest}
      ref={wrapperRef}
      justifyContent={hasContentOverflow ? 'flex-start' : rest.justifyContent}
    >
      <div ref={contentRef} style={{ width: '100%' }}>
        {children}
      </div>
    </Wrapper>
  )
}

ScrollView.defaultProps = {
  height: '100%',
  width: '100%'
}

export default ScrollView
