import get from 'lodash/get'
import { IS_PROD, STRAPI_USER_STORAGE_KEY } from '../constants'
/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = () => {
  const localUser = localStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export function formatError({ response }: any) {
  return get(response, 'data.message[0].messages[0].message', 'An unknown error has occurred.')
}

/**
 * Helper function to remove id and typename from formik values
 * as well as convert any numbers seen as a string into a number
 * @param object - Object to be cleaned.
 * @param keysToRemove - Optionally specify keys to be removed from the object.
 */
export const cleanObjectForUpdate = <T>(object: any, keysToRemove?: string[]): T => {
  delete object.id
  delete object.__typename
  Object.keys(object).forEach((key: string) => {
    /**
     * Check if value is a valid number, if it is
     * convert it to a float.
     */
    if (!isNaN(object[key]) && typeof object[key] === 'number') {
      object[key] = parseFloat(object[key])
    }
    /**
     * If value is an empty placeholder object, set value to empty string.
     * An example of this would be the EMPTY_FILE placeholder object.
     */
    if (typeof object[key] === 'object' && 'id' in object[key] && object[key] !== null) {
      if (object[key].id === '') {
        object[key] = ''
      } else {
        delete object[key]
      }
    }
  })
  // Optionally remove any unwanted keys from object
  keysToRemove?.forEach((key) => {
    delete object[key]
  })
  return object
}

/**
 * Helper function to convert video duration in seconds
 * into readble 1m20s format.
 */
export function getDurationString(duration: number) {
  const minutes = Math.floor(duration / 60)
  const seconds = duration - minutes * 60
  return `${minutes.toFixed(0)}m${seconds.toFixed(0)}s`
}

/**
 * Console logger for development environment
 */
export function logger(message?: any, ...optionalParams: any[]): void {
  if (!IS_PROD) {
    console.log(message, ...optionalParams)
  }
}

/**
 * This function will format a string from an enum,
 * making only the first letter a capital and making
 * the rest lower case, it also removes underscores
 * and replacing them with spaces.
 * @param {string} toFix - The string we are wanting
 * to format.
 * @param {boolean} allFirstCaps - If true, all words
 * in the string will get a capital first letter.
 */
export function formatString(toFix: string, firstLetterCaps: boolean, isUrl?: boolean): string {
  try {
    if (firstLetterCaps) {
      let sentence = ''
      const temp = toFix.toLowerCase().replace(/_/g, ' ').split(' ')
      temp.forEach(
        (word) => (sentence += word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() + ' ')
      )
      return sentence
    }

    if (isUrl) {
      const temp = toFix.toLowerCase().replace(' ', '-')
      return temp
    }

    return toFix.replace(/__/g, ' - ').replace(/_/g, ' ')
  } catch (error) {
    return toFix
  }
}
