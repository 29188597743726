import { Avatar, Box, Flex, Heading } from '@chakra-ui/core'
import { useAnimation, Variants } from 'framer-motion'
import get from 'lodash/get'
import * as React from 'react'
import { HelpCircle, Settings } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { ColorProps } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { useAuthContext } from '../../context/AuthProvider/index'
import { UsersPermissionsUser, useSelfQuery } from '../../generated/graphql'
import { NavItem } from '../../navigation'
import { theme } from '../../theme'
import { Text } from '../../typography'
import Header from '../Header'
import MotionFlex from '../MotionFlex'
import ScrollView from '../ScrollView'
import Version from '../Version'
import SideBarButton from './SideBarButton'
import SideBarItem from './SideBarItem'
import { MenuCont, Overlay, RenderWrapper } from './styles'

const HELP_NAV_ITEM = {
  to: '/auth/help',
  title: 'Help',
  icon: <HelpCircle size={20} color="white" className="sidebar-menu-icon" />
}

const SUBSCRIPTION_SETTINGS_NAV_ITEM = {
  to: '/auth/subscription',
  title: 'Subscription Settings',
  icon: <Settings size={20} color="white" className="sidebar-menu-icon" />
}

type SideBarProps = ColorProps & {
  accentColor: string
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  renderUserInfo?: (history: any) => React.ReactNode
  tooltipBg?: string
  tooltipColor?: string
}

const SideBar: React.FC<SideBarProps> = ({
  accentColor,
  bg,
  borderColor,
  children,
  color,
  hoverColor,
  navItems,
  tooltipBg,
  tooltipColor,
  closeOnNavigate
}) => {
  const { drawerOpen, toggleDrawer } = useAppContext()
  const { logout, user } = useAuthContext()
  const controls = useAnimation()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const history = useHistory()

  const { data } = useSelfQuery()
  const self = get(data, 'self', null) as UsersPermissionsUser
  const isSubscribed = get(data, 'self.isSubscribed', false)
  const fullName = self?.firstName && self?.lastName ? self?.firstName + ' ' + self?.lastName : ''

  React.useEffect(() => {
    controls.start('closed')
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (drawerOpen) {
      controls.start('open')
    } else {
      controls.start('closed')
    }
  }, [isTabletOrMobile, drawerOpen, controls])

  const variants: Variants = {
    open: {
      x: 0,
      width: 307,
      transition: { staggerChildren: 0.05, delayChildren: 0.05, stiffness: 10, damping: 5 }
    },
    closed: {
      x: isTabletOrMobile ? -307 : 0,
      width: 307,
      transition: {
        stiffness: 80,
        staggerDirection: -1,
        staggerChildren: 0.1
      }
    }
  }

  return (
    <>
      <MenuCont
        bg={bg}
        flexDir="column"
        animate={controls}
        variants={variants}
        alignItems="flex-start"
        // Calculate offset based on icon size
        iconOffset={(64 - 20) / 2}
        justifyContent="flex-start"
        initial={{ width: isTabletOrMobile ? 0 : 307 }}
      >
        <Flex flex="none" width="100%" height="64px" alignItems="center" justifyContent="flex-end">
          {isTabletOrMobile && (
            <SideBarButton color={color} open={drawerOpen} onClick={toggleDrawer} />
          )}
        </Flex>
        {user && (
          <MotionFlex
            py={4}
            pr={4}
            pl={'18px'}
            width="100%"
            align="flex-start"
            justify="center"
            flex="none"
            flexDirection="column"
            borderBottomWidth={1}
            borderBottomColor={borderColor}
            style={{ whiteSpace: 'nowrap' }}
            whileHover={{
              backgroundColor: get(theme, `colors.${hoverColor}`, 'pink'),
              cursor: 'pointer'
            }}
            onClick={() => {
              history.push('/auth/profile')
              toggleDrawer()
            }}
          >
            <Flex flexDirection="column" marginBottom={4} paddingTop={0} width="100%">
              <Avatar
                marginBottom={6}
                name={fullName}
                src={self?.profilePicture?.url || ''}
                height="76px"
                width="76px"
              />
              <Heading as="h2" color="solid.white" fontSize="md" marginBottom="4px">
                {fullName}
              </Heading>
              <Text color="gray.300" fontSize="sm">
                {self?.email}
              </Text>
            </Flex>
          </MotionFlex>
        )}
        <ScrollView paddingY={4}>
          {navItems.map((props) => (
            <SideBarItem
              color={color}
              key={props.title}
              hoverColor={hoverColor}
              accentColor={accentColor}
              tooltipColor={tooltipColor}
              tooltipBg={tooltipBg}
              closeOnNavigate={closeOnNavigate}
              {...props}
            />
          ))}
          {isSubscribed && (
            <SideBarItem
              color={color}
              hoverColor={hoverColor}
              accentColor={accentColor}
              tooltipColor={tooltipColor}
              tooltipBg={tooltipBg}
              closeOnNavigate={closeOnNavigate}
              {...SUBSCRIPTION_SETTINGS_NAV_ITEM}
            />
          )}
          <SideBarItem
            color={color}
            hoverColor={hoverColor}
            accentColor={accentColor}
            tooltipColor={tooltipColor}
            tooltipBg={tooltipBg}
            closeOnNavigate={closeOnNavigate}
            {...HELP_NAV_ITEM}
          />
          <Box
            cursor="pointer"
            onClick={() => {
              toggleDrawer()
              logout && logout()
              history.replace('/')
            }}
            color={color}
            fontSize="sm"
            marginY={4}
            paddingY={2}
            paddingLeft={6}
            textDecoration="underline"
          >
            Logout
          </Box>
          <Version color="gray.600" fontSize="sm" />
        </ScrollView>
      </MenuCont>
      <RenderWrapper className="render-wrapper" pl={isTabletOrMobile ? 0 : '307px'}>
        <Header />
        {children}
        {isTabletOrMobile && (
          <Overlay
            onClick={toggleDrawer}
            initial={{ opacity: 0 }}
            pointerEvents={drawerOpen ? 'auto' : 'none'}
            animate={drawerOpen ? { opacity: 1 } : { opacity: 0 }}
          />
        )}
      </RenderWrapper>
    </>
  )
}

export default SideBar

SideBar.defaultProps = {
  color: 'white',
  bg: 'solid.sidebar',
  hoverColor: 'gray.800',
  borderColor: 'gray.700',
  accentColor: 'cyan.500'
}
