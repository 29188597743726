import { ApolloQueryResult } from '@apollo/client'
import { Flex } from '@chakra-ui/core'
import React, { CSSProperties, FC, useCallback, useRef } from 'react'
import { VariableSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { FillLoader } from '..'
import { useWindowSize } from '../../hooks'

const SPACER = 16

interface InfiniteListRendererProps {
  fetchMore: () => Promise<ApolloQueryResult<any>>
  hasNextPage: boolean
  heightOffset?: number
  items: { id: number; [key: string]: any }[]
  loading: boolean
  renderComponent: React.ComponentType<any>
  // TODO: Possibly provide more explicit about types
  renderComponentProps?: any
}

/**
 * @render react
 * @name InfiniteListRenderer component
 * @description Infinite loader component.
 * @example
 *  <InfiniteListRenderer
 *    fetchMore={fetchMore}
 *    hasNextPage={hasNextPage}
 *    items={videos}
 *    loading={loading}
 *    renderComponent={VideoCard}
 *  />
 */

const InfiniteListRenderer: FC<InfiniteListRendererProps> = ({
  fetchMore,
  hasNextPage,
  heightOffset,
  items,
  loading,
  renderComponent: RenderComponent,
  renderComponentProps
}) => {
  const [windowWidth] = useWindowSize()
  const listRef = useRef<List>(null)
  const heightMap = useRef<{ [key: number]: number; height: number }>({
    height: 0
  })

  const setItemHeight = useCallback((index, height) => {
    heightMap.current = { ...heightMap.current, [index]: height }
    listRef?.current?.resetAfterIndex(index)
  }, [])

  const getItemSize = useCallback((index) => {
    return heightMap.current?.[index] + SPACER || 40
  }, [])

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = loading ? () => Promise.resolve() : fetchMore

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length

  // Render an item or a loading indicator.
  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    let content
    if (!isItemLoaded(index)) {
      content = <FillLoader />
    } else {
      content = (
        <Flex {...renderComponentProps}>
          <RenderComponent
            {...items[index]}
            index={index}
            setItemHeight={setItemHeight}
            windowWidth={windowWidth}
          />
        </Flex>
      )
    }

    return <div style={style}>{content}</div>
  }

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered }) => (
        <List
          height={window.innerHeight - (heightOffset || 64)}
          itemCount={itemCount}
          itemKey={(index) => items[index]?.id || 'c-loader'}
          itemSize={getItemSize}
          onItemsRendered={onItemsRendered}
          ref={listRef}
          width="100%"
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  )
}

export default InfiniteListRenderer
