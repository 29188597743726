import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const Home = lazy(() => import('../containers/Home'))
const Profile = lazy(() => import('../containers/Profile'))
const WatchVideo = lazy(() => import('../containers/WatchVideo'))
const Login = lazy(() => import('../containers/Login'))
const Register = lazy(() => import('../containers/Register'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const Books = lazy(() => import('../containers/Books'))
const BookPreview = lazy(() => import('../containers/BookPreview'))
const Videos = lazy(() => import('../containers/Videos'))
const EditProfile = lazy(() => import('../containers/EditProfile'))
const ChangePassword = lazy(() => import('../containers/ChangePassword'))
const VideoCollection = lazy(() => import('../containers/VideoCollection'))
const Payment = lazy(() => import('../containers/Payment'))
const BookCollection = lazy(() => import('../containers/BookCollection'))
const SubscriptionSettings = lazy(() => import('../containers/SubscriptionSettings'))
const PropheticChannel = lazy(() => import('../containers/PropheticChannel'))
const OnlineChurch = lazy(() => import('../containers/OnlineChurch'))
const SocialAuth = lazy(() => import('../containers/SocialAuth'))
const Help = lazy(() => import('../containers/Help'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  },
  {
    exact: true,
    path: '/auth/home',
    breadcrumb: 'Home',
    component: Home,
    title: 'Home'
  },
  {
    exact: true,
    path: '/auth/books',
    breadcrumb: 'Books',
    component: Books,
    title: 'Books'
  },
  {
    exact: true,
    path: '/auth/books/:id',
    breadcrumb: 'Book Preview',
    component: BookPreview,
    title: 'Preview'
  },
  {
    exact: true,
    path: '/auth/videos',
    breadcrumb: 'Videos',
    component: Videos,
    title: 'Videos'
  },
  {
    exact: true,
    path: '/auth/videos/:id',
    breadcrumb: 'Watch Video',
    component: WatchVideo,
    title: 'Watch Video'
  },
  {
    exact: true,
    path: '/auth/profile/edit',
    breadcrumb: 'Edit Information',
    component: EditProfile,
    title: 'Edit Information'
  },
  {
    exact: true,
    path: '/auth/profile/change-password',
    breadcrumb: 'Change Password',
    component: ChangePassword,
    title: 'Change Password'
  },
  {
    exact: true,
    path: '/auth/videos/collection/:id',
    breadcrumb: 'Video Collection',
    component: VideoCollection,
    title: 'Video Collection'
  },
  {
    exact: true,
    path: '/auth/subscription/payment',
    breadcrumb: 'Payment',
    component: Payment,
    title: 'Payment'
  },
  {
    exact: true,
    path: '/auth/books/collection/:id',
    breadcrumb: 'Book Collection',
    component: BookCollection,
    title: 'Book Collection'
  },
  {
    exact: true,
    path: '/auth/subscription',
    breadcrumb: 'Subscription',
    component: SubscriptionSettings,
    title: 'Subscription'
  },
  {
    exact: true,
    path: '/auth/subscription',
    breadcrumb: 'Donations',
    component: SubscriptionSettings,
    title: 'Donations'
  },
  {
    exact: true,
    path: '/auth/prophetic-channel',
    breadcrumb: 'Prophetic Channel',
    component: PropheticChannel,
    title: 'Prophetic Channel'
  },
  {
    exact: true,
    path: '/auth/online-church',
    breadcrumb: 'Online Church',
    component: OnlineChurch,
    title: 'Online Church'
  },
  {
    exact: true,
    path: '/auth/help',
    breadcrumb: 'Help',
    component: Help,
    title: 'Help'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Register',
    path: '/register',
    component: Register
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Social Login',
    path: '/auth/social/:provider',
    component: SocialAuth
  }
]

export const routes = PUBLIC_ROUTES.concat(PRIVATE_ROUTES)

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
