import { QueryResult } from '@apollo/client'
import get from 'lodash/get'

/**
 * @name usePagination hook
 * @description Handles pagination logic for the frontend.
 * @returns Object containing modified QueryResult and relay-style pageInfo
 * @example
 *  const { data, error, fetchMore, hasNextPage, loading } = usePagination(
 *    useVideoCollectionQuery({
 *      variables: {
 *        id: parseInt(id, 10),
 *        limit: 10
 *      }
 *    }),
 *    'videos'
 *  )
 */

const usePagination = (queryResult: QueryResult, paginatedField: string) => {
  const { data, error, fetchMore, loading, networkStatus, refetch } = queryResult

  const dataKey = Object.keys(data || {})[0]

  const result = get(data, `${dataKey}.${paginatedField}`, null)
  const cursor = get(data, `${dataKey}.${paginatedField}.pageInfo.endCursor`, 0)

  const fetchMoreHandler = () =>
    fetchMore({
      variables: {
        cursor: cursor
      },
      updateQuery: (
        previousQueryResult: any,
        {
          fetchMoreResult
        }: {
          fetchMoreResult?: any
          variables?: Record<string, any>
        }
      ) => {
        if (!fetchMoreResult) {
          return previousQueryResult
        }

        const previousNodes = previousQueryResult[dataKey][paginatedField]?.nodes || []
        const nextNodes = fetchMoreResult[dataKey][paginatedField]?.nodes || []
        const pageInfo = fetchMoreResult[dataKey][paginatedField]?.pageInfo

        if (nextNodes.length > 0) {
          const nextQueryResult = {
            [`${dataKey}`]: {
              ...previousQueryResult[dataKey],
              [paginatedField]: {
                __typename: previousQueryResult[dataKey][paginatedField]?.__typename,
                nodes: [...previousNodes, ...nextNodes],
                pageInfo
              }
            }
          }

          return nextQueryResult
        }

        return previousQueryResult
      }
    })

  return {
    data: {
      ...data,
      [`${dataKey}`]: {
        ...(data ? data[dataKey] : {}),
        [`${paginatedField}`]: result?.nodes || []
      }
    },
    error,
    fetchMore: fetchMoreHandler,
    hasNextPage: result?.pageInfo?.hasNextPage || false,
    loading,
    networkStatus,
    refetch
  }
}

export default usePagination
