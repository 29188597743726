import { Box, BoxProps } from '@chakra-ui/core'
// @ts-ignore - No type definitions
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import React from 'react'
import ReactMarkdown from 'react-markdown/with-html'

type MarkdownProps = BoxProps & {
  content: string
}

const Markdown: React.FC<MarkdownProps> = ({ content, ...rest }) => {
  return (
    <Box {...rest}>
      <ReactMarkdown renderers={ChakraUIRenderer()} source={content} />
    </Box>
  )
}

export default Markdown
