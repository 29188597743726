import { Modal, useDisclosure } from '@chakra-ui/core'
import get from 'lodash/get'
import React, { FC, Fragment } from 'react'
import { useSelfQuery } from '../../generated/graphql'
import SubscribeModal from '../SubscribeModal'

/**
 * @render react
 * @name RequiresSubscription component
 * @description Wraps children that are accessible only to Major 1 Connect subscribers.
 * @example
 *   <RequiresSubscription>
 *      {children}
 *   </RequiresSubscription>
 */

const RequiresSubscription: FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data } = useSelfQuery()
  const isSubscribed = get(data, 'self.isSubscribed', false)

  const childrenWithProps = React.Children.map(children, (child: any) => {
    return React.cloneElement(child, {
      onClick: !isSubscribed ? onOpen : child.props.onClick
    })
  })

  return (
    <Fragment>
      {childrenWithProps}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <SubscribeModal onClose={onClose} />
      </Modal>
    </Fragment>
  )
}

export default RequiresSubscription
