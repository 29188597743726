import { Button, Flex, Image } from '@chakra-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { images, theme } from '../../theme'
import { H2, H4 } from '../../typography'
import ScrollView from '../ScrollView'

type EmptyListHandlerProps = {
  title?: string
  subTitle?: string
}

const EmptyListHandler: React.FC<EmptyListHandlerProps> = ({ title, subTitle }) => {
  const history = useHistory()

  return (
    <ScrollView alignItems="center" justifyContent="center">
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        margin="0 auto"
        width="100%"
        maxWidth="320px"
        textAlign="center"
      >
        <Image size={80} src={images.majorOneLogo} />
        <Flex my={3} marginTop={8} flexDir="column">
          <H2 color="solid.teal" textAlign="center" fontWeight={600}>
            {title}
          </H2>
          <H4 color="gray.500" my={5} textAlign="center">
            {subTitle}
          </H4>
        </Flex>
        <Button
          boxShadow={theme.buttonShadow}
          fontSize="sm"
          marginBottom={2}
          rounded="lg"
          size="lg"
          variantColor="brand"
          onClick={() => history.replace('/')}
        >
          Back Home
        </Button>
      </Flex>
    </ScrollView>
  )
}

export default EmptyListHandler

EmptyListHandler.defaultProps = {
  title: 'There is nothing here at the moment...',
  subTitle: 'Please check again later'
}
