import { ApolloError, ApolloQueryResult } from '@apollo/client'
import { BoxProps, Button, Flex } from '@chakra-ui/core'
import React, { FC, Fragment } from 'react'
import { AlertTriangle } from 'react-feather'
import { Exact } from '../../generated/graphql'
import { theme } from '../../theme'
import { H4 } from '../../typography'
import FillLoader from '../FillLoader'
import ScrollView from '../ScrollView'

// FC components break when referencing React.ReactNode types for props
// https://stackoverflow.com/questions/54905376/type-error-jsx-element-type-null-undefined-is-not-a-constructor-functi

type GqlStateHandlerProps = BoxProps & {
  customError?: any
  customLoader?: any
  error: ApolloError | undefined
  loading: boolean
  onRetry?: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<any>>
}

/**
 * @render react
 * @name GqlStateHandler component
 * @description Handles GraphQL state UI.
 * @example
 * <GqlStateHandler error={error} loading={loading}>
 *   {children}
 * </GqlStateHandler>
 */

const GqlStateHandler: FC<GqlStateHandlerProps> = ({
  children,
  customLoader,
  customError,
  error,
  loading,
  onRetry,
  ...rest
}) => {
  if (loading) {
    return customLoader || <FillLoader />
  }

  if (error) {
    return (
      <ScrollView justifyContent="center" {...rest}>
        {customError || (
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            margin="0 auto"
            width="100%"
            maxWidth="320px"
            textAlign="center"
            onClick={() => onRetry && onRetry()}
          >
            <AlertTriangle color={theme.colors.red[500]} size="56px" strokeWidth={1.5} />
            <H4 color="gray.500" marginTop={4} textAlign="center">
              An error has occurred
            </H4>
            <Button
              color="solid.teal"
              fontSize="sm"
              marginTop={2}
              minWidth="auto"
              rounded="lg"
              size="lg"
              variant="link"
            >
              Tap to retry
            </Button>
          </Flex>
        )}
      </ScrollView>
    )
  }

  return <Fragment>{children}</Fragment>
}

export default GqlStateHandler
