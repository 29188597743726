import React, { createRef, FC, useEffect, useRef } from 'react'
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import 'video.js/dist/video-js.css'

interface LiveStreamProps {
  options?: VideoJsPlayerOptions
  type?: string
  url: string
}

/**
 * @render react
 * @name LiveStream component
 * @description Describe the purpose of this component.
 * @example
 *   <LiveStream />
 */

const LiveStream: FC<LiveStreamProps> = ({ options, type, url }) => {
  const playerRef = createRef<HTMLVideoElement>()
  const player = useRef<VideoJsPlayer>()

  const defaultOptions: VideoJsPlayerOptions = {
    autoplay: true,
    controls: true,
    fluid: true,
    sources: [
      {
        src: url || '',
        type: type || 'application/x-mpegURL'
      }
    ],
    ...options
  }

  useEffect(() => {
    if (playerRef && playerRef.current) {
      player.current = videojs(playerRef.current, defaultOptions)
    }
    return () => {
      if (player && player.current) {
        player.current.dispose()
      }
    }
  }, [defaultOptions, playerRef])

  return <video ref={playerRef} className="video-js" style={{}} />
}

export default LiveStream
