import { Avatar, Button, Flex, Heading } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import get from 'lodash/get'
import * as React from 'react'
import { ChevronLeft, User } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, withRouter } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { color, ColorProps, space } from 'styled-system'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useAppContext } from '../../context/AppProvider'
import { UploadFile, useSelfQuery } from '../../generated/graphql'
import { routes } from '../../navigation/routes'
import { images, theme } from '../../theme'
import MotionFlex from '../MotionFlex'
import SideBarButton from '../SideBar/SideBarButton'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

type HeaderContProps = {
  color?: string
  open?: boolean
}

const HeaderCont = styled(MotionFlex)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1290;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  left: ${(props) => (props.open ? '307px' : '307px')};

  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const history = useHistory()
  const { appTitle, drawerOpen, toggleDrawer } = useAppContext()

  const { data } = useSelfQuery()
  const profilePicture = get(data, 'self.profilePicture', null) as UploadFile

  const breadcrumbs = useBreadcrumbs(routes)
  const slicedBreadcrumbs = breadcrumbs.slice(1)

  const isPreviewingBook = parseInt(
    useRouteMatch<{ id?: string }>('/auth/books/:id/')?.params.id || '',
    10
  )
  const isWatchingVideo = parseInt(
    useRouteMatch<{ id?: string }>('/auth/videos/:id/')?.params.id || '',
    10
  )
  const onProfile = Boolean(useRouteMatch('/auth/profile'))
  const onPayment = Boolean(useRouteMatch('/auth/subscription/payment'))

  return (
    <HeaderCont
      {...rest}
      backgroundColor={isPreviewingBook ? 'solid.teal' : rest.backgroundColor}
      borderBottomWidth={slicedBreadcrumbs.length > 2 ? 0 : 1}
    >
      <Flex flex="none" width="64px">
        {!onPayment && (
          <React.Fragment>
            {slicedBreadcrumbs.length > 2 ? (
              <Button
                variant="ghost"
                height="48px"
                marginLeft={2}
                padding={0}
                width="48px"
                onClick={() =>
                  isWatchingVideo ? history.replace('/auth/videos') : history.goBack()
                }
              >
                <ChevronLeft color={isPreviewingBook ? 'white' : 'black'} size={22} />
              </Button>
            ) : (
              isTabletOrMobile && (
                <SideBarButton color="black" open={drawerOpen} onClick={toggleDrawer} />
              )
            )}
          </React.Fragment>
        )}
      </Flex>
      <Flex flex={1} justifyContent="center">
        {appTitle ? (
          <Heading as="h1" color="gray.400" fontSize="12px" fontFamily="body" margin={0}>
            {appTitle}
          </Heading>
        ) : (
          <motion.img
            width="auto"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={images.majorOneLogo}
            style={{ alignSelf: 'flex-start', height: 40 }}
          />
        )}
      </Flex>
      <Flex flex="none" justifyContent="flex-end" paddingRight={2} width="64px">
        {slicedBreadcrumbs.length <= 2 && !onProfile && (
          <Button padding={0} rounded="full" onClick={() => history.push('/auth/profile')}>
            {profilePicture ? (
              <Avatar
                borderColor="solid.green"
                borderWidth={1}
                name={data?.self?.firstName + ' ' + data?.self?.lastName}
                src={profilePicture.url || ''}
              />
            ) : (
              <Flex
                bg="white"
                width="100%"
                height="100%"
                align="center"
                justify="center"
                borderColor="solid.green"
                borderWidth={1}
                borderRadius="50%"
                position="absolute"
              >
                <User color={get(theme.colors, 'gray.500', 'gray')} size={24} strokeWidth={1.5} />
              </Flex>
            )}
          </Button>
        )}
      </Flex>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  backgroundColor: 'white'
}
