import { Button, ButtonProps, Flex } from '@chakra-ui/core'
import * as React from 'react'
import { Text } from '../../typography'

type HomeIconButtonProps = Omit<ButtonProps, 'children'> & {
  icon: React.ElementType
  text: string
}

const HomeIconButton: React.FC<HomeIconButtonProps> = ({ icon: Icon, text, ...rest }) => {
  return (
    <Button {...rest}>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Icon size={40} color="white" />
        <Text fontSize={['11px', '14px']} mt={2} color="white">
          {text}
        </Text>
      </Flex>
    </Button>
  )
}

export default HomeIconButton

HomeIconButton.defaultProps = {
  p: 5,
  mr: 4,
  width: 100,
  height: 100,
  rounded: 'lg',
  bg: 'solid.green',
  whiteSpace: 'unset'
}
