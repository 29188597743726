import { Button, ButtonProps, Flex, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import * as React from 'react'

type SocialButtonProps = Omit<ButtonProps, 'children'> & {
  icon: React.ElementType
  text: string
}

const IconCont = styled(Flex)`
  width: 48px;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const SocialButton: React.FC<SocialButtonProps> = ({ icon: Icon, text, ...rest }) => {
  return (
    <Button {...rest}>
      <IconCont roundedTopLeft="md" roundedBottomLeft="md" bg="solid.aqua">
        <Icon color="white" />
      </IconCont>
      <Text fontSize="sm" paddingX={4}>
        {text}
      </Text>
    </Button>
  )
}

export default SocialButton

SocialButton.defaultProps = {
  variant: 'solid',
  variantColor: 'gray',
  width: '100%',
  rounded: 'lg',
  justifyContent: 'flex-start',
  color: 'solid.aqua',
  paddingX: 0
}
