import { Flex, FlexProps, Image } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { Variants } from 'framer-motion'
import * as React from 'react'
import { MotionFlex } from '..'
import { images } from '../../theme'
import Version from '../Version'

const PanelWrapper = styled(MotionFlex)`
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;
  display: flex;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    width: 100%;
    position: relative;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const variants: Variants = {
  hidden: {
    x: '-350px'
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 40
    }
  }
}

const SideSlider: React.FC<FlexProps> = ({ children, justify }) => {
  return (
    <PanelWrapper
      p={5}
      bg="white"
      initial="hidden"
      animate="visible"
      variants={variants}
      rounded={['md', 0]}
    >
      <Flex flexDir="column" minHeight="100%" width="100%" justify={justify}>
        <Flex justify="center" width="100%" p={8}>
          <Image width="64px" alignSelf="center" src={images.majorOneLogo} />
        </Flex>
        {children}
        <Version color="gray.400" fontSize="sm" marginTop={2} />
      </Flex>
    </PanelWrapper>
  )
}

export default SideSlider

SideSlider.defaultProps = {
  justify: 'center'
}
