import {
  AspectRatioBox,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/core'
import { useStripe } from '@stripe/react-stripe-js'
import get from 'lodash/get'
import React, { FC, useState } from 'react'
import { Card } from '..'
import { ERROR_TOAST } from '../../constants'
import { useCreateCheckoutSessionMutation } from '../../generated/graphql'
import { images, theme } from '../../theme'
import { logger } from '../../utils'
import Toast from '../Toast'

// TODO: extract redirectToCheckout logic to useCheckout hook

interface SubscribeModalProps {
  hideCloseButton?: boolean
  onClose: () => void
}

/**
 * @render react
 * @name SubscribeModal component
 * @description Modal rendered when an unsubscrbed user interacts with subscription-only content.
 * @example
 *   const { isOpen, onOpen, onClose } = useDisclosure()
 *   ...
 *   <Modal isCentered isOpen={isOpen} onClose={onClose}>
 *     <SubscribeModal />
 *   </Modal>
 */

const SubscribeModal: FC<SubscribeModalProps> = ({ hideCloseButton, onClose }) => {
  const stripe = useStripe()
  const [redirecting, setRedirecting] = useState(false)
  const [createCheckoutSession] = useCreateCheckoutSessionMutation({
    onError: () =>
      toast({
        ...ERROR_TOAST,
        render: ({ onClose }) => (
          <Toast status="error" title="There has been an error" onClose={onClose} />
        )
      })
  })
  const toast = useToast()

  const redirectToCheckout = async () => {
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setRedirecting(true)

    try {
      const checkoutSession = await createCheckoutSession()

      if (!checkoutSession) {
        throw new Error('Redirection to Checkout failed. Try again...')
      }

      const checkoutSessionId = get(checkoutSession.data, 'checkoutSession.checkoutSessionId', null)

      if (!checkoutSessionId) {
        logger('Missing checkout_session_id!')

        throw new Error('There has been an error.')
      }

      const result = await stripe.redirectToCheckout({
        sessionId: checkoutSessionId
      })

      if (result?.error) {
        throw new Error('Redirection to Checkout failed. Try again...')
      }
    } catch (error) {
      setRedirecting(false)

      toast({
        ...ERROR_TOAST,
        render: ({ onClose }) => (
          <Toast
            status="error"
            title={error?.message || 'There has been an error'}
            onClose={onClose}
          />
        )
      })
    }
  }

  const subscriptionAmount = 5.99

  return (
    <React.Fragment>
      <ModalOverlay />
      <ModalContent shadow="none" bg="transparent" rounded="md">
        <Card
          rounded="16px"
          shadow="md"
          m={4}
          overflow="auto"
          borderWidth={0}
          justifyContent="flex-start"
          position="relative"
        >
          <AspectRatioBox backgroundColor="gray.50" ratio={4 / 3}>
            <Image src={images.bushiri} objectFit="cover" />
          </AspectRatioBox>
          {!hideCloseButton && (
            <ModalCloseButton
              backgroundColor="gray.300"
              color="white"
              rounded="full"
              size="lg"
              top="12px"
            />
          )}

          <Flex
            alignItems="center"
            color="solid.teal"
            flexDirection="column"
            justifyContent="center"
            padding={4}
          >
            <Heading as="h2" fontSize="lg" marginY={2}>
              Elevate your faith
            </Heading>
            <Heading as="h3" fontSize="md" fontWeight={500} marginBottom={2}>
              Subscribe to Major 1 Connect
            </Heading>
            <Text color="solid.green" fontSize="20px" fontWeight={600}>
              US${subscriptionAmount}
              <Text as="span" fontSize="80%" fontWeight={500} marginLeft={1}>
                p/m
              </Text>
            </Text>
            <Text color="gray.400" fontSize="10px">
              Cancel anytime
            </Text>
          </Flex>
          <Box padding={4} paddingTop={3} width="100%">
            <Button
              isDisabled={!stripe || redirecting}
              isFullWidth
              boxShadow={theme.buttonShadow}
              fontSize="sm"
              marginBottom={2}
              rounded="lg"
              size="lg"
              variantColor="brand"
              onClick={() => redirectToCheckout()}
            >
              {redirecting ? 'Redirecting to Checkout...' : 'Subscribe Now'}
            </Button>
            <Button
              isFullWidth
              fontSize="sm"
              rounded="lg"
              size="lg"
              variant="ghost"
              variantColor="brand"
              onClick={() => {
                setRedirecting(false)
                onClose()
              }}
            >
              Maybe Later
            </Button>
          </Box>
        </Card>
      </ModalContent>
    </React.Fragment>
  )
}

export default SubscribeModal
