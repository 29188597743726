import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import * as React from 'react'
import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader } from './components'
import { STRIPE_PUBLISHABLE_KEY } from './constants'
import Navigation from './navigation'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY || '')

const App: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Suspense fallback={<FillLoader />}>
          <Navigation />
        </Suspense>
      </Router>
    </Elements>
  )
}

export default App
