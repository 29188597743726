import * as React from 'react'

type AppContextType = {
  appTitle?: string
  drawerOpen: boolean
  quoteVisible: boolean
  toggleDrawer: () => void
  setAppTitle: React.Dispatch<React.SetStateAction<string | undefined>>
  setQuoteVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = React.createContext<AppContextType>({
  appTitle: undefined,
  drawerOpen: false,
  quoteVisible: true,
  toggleDrawer: () => null,
  setAppTitle: () => {},
  setQuoteVisible: () => false
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [quoteVisible, setQuoteVisible] = React.useState(true)
  const [appTitle, setAppTitle] = React.useState()

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <AppContext.Provider
      value={{ appTitle, quoteVisible, drawerOpen, setAppTitle, toggleDrawer, setQuoteVisible }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
