import { Modal, useDisclosure } from '@chakra-ui/core'
import React, { Fragment, Suspense } from 'react'
import { BookOpen, Cast, Home, PlayCircle, Tv, User } from 'react-feather'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { useLocation } from 'react-router-dom'
import { FillLoader, LiveStreamModal, VideoModal } from '../components'
import SideBar from '../components/SideBar'
import PageNotFound from '../containers/PageNotFound'
import { useAuthContext } from '../context/AuthProvider'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'

interface RouteType extends RouteProps {
  component: any
}

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

const NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/home`,
    title: 'Home',
    icon: <Home size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/online-church`,
    title: 'Online Church',
    icon: <Cast size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/prophetic-channel`,
    title: 'Prophetic Channel',
    icon: <Tv size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/videos`,
    title: 'Videos',
    icon: <PlayCircle size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/books`,
    title: 'Books',
    icon: <BookOpen size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/profile`,
    title: 'My Profile',
    icon: <User size={20} color="white" className="sidebar-menu-icon" />
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated } = useAuthContext()

  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Suspense fallback={<FillLoader color="black" />}>
            <Component {...rest} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)

const Navigation = () => {
  const { onClose } = useDisclosure()
  // TODO: Find better types for 'background'
  const location = useLocation<{ background: any }>()
  const background = location.state?.background

  return (
    <Fragment>
      <Switch location={background || location}>
        {PUBLIC_ROUTES.map((route) => {
          return <PublicRoute key={route.path} {...route} />
        })}
        <Route
          path="/auth"
          render={() => (
            <SideBar
              bg="solid.sidebar"
              color="white"
              navItems={NAV_ITEMS}
              hoverColor="brand.800"
              accentColor="cyan.500"
              closeOnNavigate={true}
            >
              {PRIVATE_ROUTES.map((route) => {
                return <PrivateRoute key={route.path} {...route} />
              })}
            </SideBar>
          )}
        />
        <Route render={PageNotFound} />
      </Switch>
      {/* Show the modal when a background page is set */}
      {background && (
        <Fragment>
          <Route
            path="/auth/videos/:id"
            children={
              <Modal isCentered isOpen={!!background} onClose={onClose}>
                <VideoModal onClose={onClose} />
              </Modal>
            }
          />
          <Route
            path="/auth/prophetic-channel"
            children={
              <Modal isCentered isOpen={!!background} onClose={onClose}>
                <LiveStreamModal />
              </Modal>
            }
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default Navigation
