import { Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { PlayCircle } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom'
import { Videos } from '../../generated/graphql'
import { Text } from '../../typography'
import { getDurationString } from '../../utils'

interface VideoCardProps extends Videos {
  index?: number
  overflowTitle?: boolean
  setItemHeight?: (index?: number, size?: number) => void
  windowWidth?: number
}

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
`

/**
 * @render react
 * @name VideoCard component
 * @description Renders a video card.
 * @example
 * <VideoCard title="Video" video={videoURL}  />
 */

const VideoCard: React.FC<VideoCardProps> = ({
  index,
  overflowTitle,
  setItemHeight,
  title,
  video,
  windowWidth
}) => {
  const location = useLocation()
  const [duration, setDuration] = React.useState('Loading...')

  const playerRef = React.createRef<HTMLVideoElement>()

  const { push } = useHistory()

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (setItemHeight) {
      setItemHeight(index, wrapperRef.current?.getBoundingClientRect().height)
    }
    // eslint-disable-next-line
  }, [windowWidth])

  React.useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.src = video?.url ? `${video.url}#t=10` : ''
    }
  }, [playerRef, video])

  return (
    <Flex
      ref={wrapperRef}
      className="publisher-card"
      flex={1}
      flexDirection="column"
      marginBottom={2}
    >
      <Flex
        backgroundColor="gray.400"
        flexDirection="column"
        height={200}
        overflow="hidden"
        position="relative"
        rounded={8}
        onClick={() => push(`/auth/videos/${video?.id}`, { background: location, video })}
      >
        <StyledVideo
          preload="auto"
          ref={playerRef}
          onLoadedMetadata={() => {
            if (playerRef && playerRef.current) {
              setDuration(getDurationString(playerRef.current.duration))
            }
          }}
          controls={false}
          className="video-js"
        />
        <Overlay>
          <PlayCircle size={48} color="white" />
        </Overlay>
        <Flex
          p={4}
          width="100%"
          height="100%"
          borderRadius={10}
          position="absolute"
          backgroundSize="cover"
          backgroundPosition="center"
        >
          <Flex flexDirection="column" justifyContent="flex-end">
            <Text color="solid.white">{duration}</Text>
            {!overflowTitle && (
              <Text fontWeight="bold" color="solid.white">
                {title}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      {overflowTitle && (
        <Text fontSize="sm" fontWeight={600} color="solid.teal" marginY={2}>
          {title}
        </Text>
      )}
    </Flex>
  )
}

export default React.memo(VideoCard)
