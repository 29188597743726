import { ApolloProvider } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { css, Global } from '@emotion/core'
import React from 'react'
import 'react-dates/initialize'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import client from './apollo'
import App from './App'
import { APP_NAME, BUGSNAG_API_KEY } from './constants'
import { AppProvider, AuthProvider } from './context'
import * as serviceWorker from './serviceWorker'
import { theme } from './theme'

if (BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()]
  })
}

// @ts-ignore - Bugsnag.getPlugin('react') possibly undefined
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Helmet titleTemplate={`${APP_NAME} | %s`} />
            <AppProvider>
              <CSSReset />
              <Global
                styles={css`
                  * {
                    font-family: ${theme.fonts.body};
                  }
                  h1,
                  h2,
                  h3,
                  h4,
                  h5,
                  h6 {
                    font-family: ${theme.fonts.heading};
                  }
                `}
              />
              <App />
            </AppProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
