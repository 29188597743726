import { Flex } from '@chakra-ui/core'
import { AnimatePresence } from 'framer-motion'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useAppContext } from '../../../context/AppProvider'
import { Text } from '../../../typography'
import { MenuItem } from './styles'

type SideBarItemProps = {
  title: string
  to: string
  color: string
  hoverColor: string
  accentColor: string
  icon: React.ReactNode
  tooltipColor?: string
  tooltipBg?: string
  closeOnNavigate?: boolean
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  accentColor,
  color,
  hoverColor,
  icon,
  title,
  to,
  tooltipColor,
  tooltipBg,
  closeOnNavigate
}) => {
  const { toggleDrawer } = useAppContext()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  return (
    <MenuItem
      to={to}
      color={color}
      bg={accentColor}
      hovercolor={hoverColor}
      accentcolor={accentColor}
      activeClassName="active-nav-link"
      {...(closeOnNavigate && isTabletOrMobile ? { onClick: toggleDrawer } : {})}
    >
      <Flex
        height="50px"
        alignItems="center"
        justifyContent="center"
        className="sidebar-nav-item-wrapper"
      >
        <Flex className="icon-wrap">{icon}</Flex>
        <AnimatePresence>
          <Text
            ml={4}
            color={color}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, pointerEvents: 'none' }}
          >
            {title}
          </Text>
        </AnimatePresence>
      </Flex>
    </MenuItem>
  )
}

export default SideBarItem
