import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { errorLogger, queryLogger } from 'the-apollo-utilities'
import { STRAPI_USER_STORAGE_KEY } from './constants'
import introspectionResult from './fragment-matcher'
import { fetchJwt } from './utils'

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_HOST + '/graphql'
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from (local/session) storage if it exists
  const token = fetchJwt()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ response }) => {
  if (response?.errors && response.errors[0].message === 'Invalid token.') {
    window.localStorage.removeItem(STRAPI_USER_STORAGE_KEY)
    window.sessionStorage.removeItem(STRAPI_USER_STORAGE_KEY)
    window.location.replace(process.env.REACT_APP_CLIENT_HOST || '')
  }
})

const retryLink = new RetryLink()

let clientLink = from([retryLink, errorLink, authLink, httpLink])

if (process.env.NODE_ENV === 'development') {
  clientLink = from([queryLogger, errorLogger, clientLink])
}

const client = new ApolloClient({
  cache,
  link: clientLink
})

export default client
