export default {
  bg: require('../assets/images/core-bg.jpg'),
  majorOneLogo: require('../assets/images/colour.svg'),
  majorOneLogoWhite: require('../assets/images/white.svg'),
  404: require('../assets/images/404.svg'),
  noData: require('../assets/images/no-data.png'),
  bushiri: require('../assets/images/Bushiri.png'),
  bannerBg: require('../assets/images/banner-bg.jpg'),
  paymentSuccess: require('../assets/images/payment-success.json'),
  paymentCancelled: require('../assets/images/payment-cancelled.json')
}
