import { Button } from '@chakra-ui/core'
import * as React from 'react'
import { MotionFlex } from '..'
import { useThrottledResize } from '../../hooks'
import { images } from '../../theme'
import { H4, Text } from '../../typography'
import RevealFlex from '../RevealFlex'

type QuoteProps = {
  firstName: string | null | undefined
  quote: string | null | undefined
  onClose?: () => void
  onGetHeight?: (height: number) => void
}

const Quote: React.FC<QuoteProps> = ({ firstName, quote, onClose, onGetHeight }) => {
  const [height, setHeight] = React.useState(0)

  const quoteRef = React.useRef<HTMLDivElement>(null)

  const { height: resizeHeight } = useThrottledResize(quoteRef)

  const currentTime = new Date().getHours()
  let timeOfDay

  if (currentTime < 12) {
    timeOfDay = 'morning'
  } else if (currentTime >= 12 && currentTime < 17) {
    timeOfDay = 'afternoon'
  } else if (currentTime >= 17 && currentTime < 24) {
    timeOfDay = 'evening'
  }

  React.useEffect(() => {
    if (quoteRef && quoteRef.current) {
      setHeight(quoteRef.current.offsetHeight)
      onGetHeight && onGetHeight(quoteRef.current.offsetHeight)
    }
  }, [onGetHeight])

  React.useEffect(() => {
    if (resizeHeight && resizeHeight !== height) {
      setHeight(resizeHeight)
      onGetHeight && onGetHeight(resizeHeight)
    }
  }, [resizeHeight, onGetHeight, height])

  return (
    <MotionFlex
      left={0}
      right={0}
      width="100%"
      ref={quoteRef}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0, y: -height, transition: { duration: 0.25, ease: 'easeOut' } }}
    >
      <RevealFlex
        px={4}
        py={5}
        flex={1}
        width="100%"
        position="relative"
        backgroundSize="cover"
        backgroundImage={`url(${images.bannerBg})`}
      >
        <MotionFlex width="100%" justify="flex-start">
          {firstName && (
            <H4 textAlign="left" color={'solid.white'}>
              {timeOfDay ? `Good ${timeOfDay}` : 'Hello'}, {firstName}
            </H4>
          )}
        </MotionFlex>
        <MotionFlex width="100%" justify="flex-start">
          <Text my={5} textAlign="left" color={'solid.white'}>
            {quote}
          </Text>
        </MotionFlex>
        <MotionFlex onClick={onClose} width="100%" justify="flex-end">
          <Button color="white" variant="ghost" size="xs">
            Dismiss
          </Button>
        </MotionFlex>
      </RevealFlex>
    </MotionFlex>
  )
}

export default Quote
