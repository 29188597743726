import { Button, ButtonGroup, Flex, Image } from '@chakra-ui/core'
import truncate from 'lodash/truncate'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import removeMD from 'remove-markdown'
import { Books } from '../../generated/graphql'
import { Text } from '../../typography'

/**
 * Renders an extract of the book's description
 * @param body Markdown of book
 */
const markdownExtract = (body: string, length: number = 80) => {
  const text = removeMD(body)
  return truncate(text, { length })
}

interface BookCardProps extends Books {
  index?: number
  setItemHeight?: (index?: number, size?: number) => void
  windowWidth?: number
}

/**
 * @render react
 * @name BookCard component
 * @description Renders a book card.
 * @example
 * <BookCard id="1" title="Book" ...  />
 */

const BookCard: React.FC<BookCardProps> = ({
  id,
  title,
  author,
  description,
  buyNowLink,
  previewLink,
  cover,
  index,
  setItemHeight,
  windowWidth
}) => {
  const history = useHistory()
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (setItemHeight) {
      setItemHeight(index, wrapperRef.current?.getBoundingClientRect().height)
    }
    // eslint-disable-next-line
  }, [windowWidth])

  return (
    <Flex
      ref={wrapperRef}
      marginBottom={4}
      padding={4}
      paddingLeft={0}
      bg="white"
      pos="relative"
      rounded={16}
    >
      <Flex
        flex="none"
        alignItems="center"
        size={144}
        marginRight={1}
        onClick={() => history.push(`/auth/books/${id}`)}
      >
        <Image src={cover?.url || ''} />
      </Flex>
      <Flex flex={1} flexDirection="column">
        <Flex flexDirection="column" onClick={() => history.push(`/auth/books/${id}`)}>
          <Text fontFamily="heading" fontSize={14} fontWeight="bold">
            {title}
          </Text>
          <Text color="solid.teal" fontSize={12} fontWeight={600} marginY={2}>
            {author}
          </Text>
          <Text fontSize={12}>{markdownExtract(description || '')}</Text>
        </Flex>
        <ButtonGroup size="sm" marginTop={2}>
          <Button
            isDisabled={!buyNowLink}
            marginTop={2}
            variantColor="brand"
            onClick={() => buyNowLink && window.open(buyNowLink, '_blank')}
          >
            Buy Now
          </Button>
          <Button
            isDisabled={!previewLink}
            marginTop={2}
            variant="outline"
            variantColor="brand"
            onClick={() => previewLink && window.open(previewLink, '_blank')}
          >
            Preview
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  )
}

export default React.memo(BookCard)
