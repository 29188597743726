import { AspectRatioBox, Badge, BoxProps, Flex, Image, Text } from '@chakra-ui/core'
import moment from 'moment'
import React, { FC } from 'react'
import { Cast, Clock } from 'react-feather'
import { OnlineChurch, UploadFile } from '../../generated/graphql'

interface Props extends BoxProps {
  data: OnlineChurch
  poster?: UploadFile
}

/**
 * @render react
 * @name OnlineChurchCard component
 * @description Renders an "Online Church" card.
 * @example
 *   <OnlineChurchCard data={...} />
 */

const OnlineChurchCard: FC<Props> = ({ data, poster, ...rest }) => {
  const liveStreamDateTime = moment(new Date(data?.dateTime))
  const isStreamToday = moment().isSame(new Date(data?.dateTime), 'day')

  const renderStartTime = () => {
    if (!data?.dateTime) {
      return (
        <Text alignSelf="flex-end" ml={3} color={'solid.white'}>
          There is no scheduled stream at the moment. Please check again later.
        </Text>
      )
    }

    let message = 'Starts at ' + liveStreamDateTime.format('HH:mm a')

    if (!isStreamToday) {
      const liveStreamDate = liveStreamDateTime.format('D MMM yyyy')
      message = 'Starts ' + liveStreamDate + ' at ' + liveStreamDateTime.format('HH:mm a')
    }

    return (
      <Flex flexDirection="row" alignItems="center">
        <Clock size={20} color="white" className="sidebar-menu-icon" />
        <Text ml={3} color={'solid.white'}>
          {message}
        </Text>
      </Flex>
    )
  }

  const renderBadge = () => {
    if (!data) {
      return (
        <Flex>
          <Badge variantColor="gray">No Scheduled Stream</Badge>
        </Flex>
      )
    }

    let message = 'Next'
    let variantColor = 'gray'

    // TODO: Need to update BE to return stream endTime to compute isLive and isEnded
    const isLive = false
    const hasEnded = false

    if (isLive) {
      message = 'Live'
      variantColor = 'green'
    }

    if (hasEnded) {
      message = 'Ended'
      variantColor = 'red'
    }

    return (
      <Flex>
        <Badge variantColor={variantColor}>{message}</Badge>
      </Flex>
    )
  }

  return (
    <AspectRatioBox marginTop={5} maxWidth={500} ratio={16 / 10} {...rest}>
      <React.Fragment>
        <Flex
          alignItems="center"
          borderRadius={10}
          bottom={0}
          height="100%"
          justifyContent="center"
          overflow="hidden"
          position="absolute"
          top={0}
          width="100%"
        >
          {poster ? (
            <Image src={poster?.url} objectFit="cover" />
          ) : (
            <Cast color="white" size={80} strokeWidth={1.5} />
          )}
        </Flex>
        <Flex
          backgroundColor="rgba(0,0,0,.4)"
          borderRadius={10}
          height="100%"
          position="absolute"
          top={0}
          width="100%"
        >
          <Flex flexDirection="column" justifyContent="space-between" p={3}>
            {renderBadge()}
            {renderStartTime()}
          </Flex>
        </Flex>
      </React.Fragment>
    </AspectRatioBox>
  )
}

export default OnlineChurchCard
