import { RefObject, useLayoutEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export interface DOMRectReadOnly {
  readonly x: number
  readonly y: number
  readonly width: number
  readonly height: number
  readonly top: number
  readonly right: number
  readonly bottom: number
  readonly left: number
}

/**
 * @name useMeasure hook
 * @description Tracks dimensions of an HTML element using the Resize Observer API.
 * @returns Object containing the size of an element and its position relative to the viewport
 * @example
 *  const { x, y, height, width, ... } = useMeasure(elementRef)
 */

const useMeasure = (ref: RefObject<HTMLElement | null>) => {
  const [bounds, setContentRect] = useState<DOMRectReadOnly>(
    // DOMRectReadOnly.fromRect()
    { x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 }
  )

  useLayoutEffect(() => {
    if (ref.current === null) {
      return
    }

    let animationFrameId: number | null = null
    const measure: ResizeObserverCallback = ([entry]) => {
      animationFrameId = window.requestAnimationFrame(() => {
        setContentRect(entry.contentRect)
      })
    }

    const ro = new ResizeObserver(measure)
    ro.observe(ref.current!)

    return () => {
      window.cancelAnimationFrame(animationFrameId!)
      ro.disconnect()
    }
  }, [ref])

  return bounds
}

export default useMeasure
