import { ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/core'
import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'
import { useHistory, useLocation } from 'react-router-dom'
import 'video.js/dist/video-js.css'
import { UploadFile } from '../../generated/graphql'

interface VideoModalProps {
  onClose: () => void
}

const VideoModal: React.FC<VideoModalProps> = ({ onClose }) => {
  const history = useHistory()
  const { state } = useLocation<{ video: UploadFile }>()

  return (
    <Fragment>
      <ModalOverlay onClick={() => history.goBack()} />
      <ModalContent shadow="none" bg="transparent" maxWidth="40rem" rounded="md">
        <ReactPlayer url={state.video?.url || ''} controls playsinline width="100%" height="auto" />
        <ModalCloseButton
          backgroundColor="gray.300"
          color="white"
          rounded="full"
          size="lg"
          top="12px"
          zIndex={100}
          onClick={() => history.goBack()}
        />
      </ModalContent>
    </Fragment>
  )
}

export default VideoModal
