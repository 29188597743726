import { ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/core'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import 'video.js/dist/video-js.css'
import LiveStream from '../LiveStream'

interface LiveStreamModalProps {
  title?: string
}

const LiveStreamModal: React.FC<LiveStreamModalProps> = ({ title }) => {
  const history = useHistory()
  const { state } = useLocation<{ url: string }>()

  return (
    <Fragment>
      <Helmet>
        <title>{title ? title + ' - Live Stream' : 'Live Stream'}</title>
      </Helmet>
      <ModalOverlay onClick={() => history.goBack()} />
      <ModalContent shadow="none" bg="transparent" maxWidth="40rem" rounded="md">
        <LiveStream url={state.url} />
        <ModalCloseButton
          backgroundColor="gray.300"
          color="white"
          rounded="full"
          size="lg"
          top="12px"
          zIndex={1}
          onClick={() => history.goBack()}
        />
      </ModalContent>
    </Fragment>
  )
}

export default LiveStreamModal
