import { theme as chakraTheme } from '@chakra-ui/core'
import images from './images'

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Work Sans, sans-serif',
    mono: 'Work Sans, sans-serif',
    heading: 'Merriweather, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    brand: {
      50: '#e9fafb',
      100: '#c6eef2',
      200: '#a0e4e9',
      300: '#7cdae1',
      400: '#5ed0d9',
      500: '#4cb7c0',
      600: '#3b8e94',
      700: '#2b656a',
      800: '#183d3f',
      900: '#041516'
    },
    green: {
      ...chakraTheme.colors.green,
      50: 'rgba(140,197,63,0.15)'
    },
    solid: {
      white: '#ffffff',
      aqua: '#36B7C9',
      teal: '#08585C',
      green: '#8CC53F',
      sidebar: '#062123'
    }
  },
  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.08)',
  buttonShadow: '0px 10px 20px 0px rgba(0,0,0,0.14)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

const primaryButtonStyles = {
  isFullWidth: true,
  fontSize: 'sm',
  height: 50,
  marginTop: 4,
  boxShadow: theme.buttonShadow,
  rounded: 'lg',
  size: 'lg' as 'lg',
  variantColor: 'brand'
}

export { theme, images, primaryButtonStyles }
