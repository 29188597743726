import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/core'
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-feather'
import { SUCCESS_TOAST } from '../../constants'
import { images, theme } from '../../theme'
import { logger } from '../../utils'
import Card from '../Card'
import Toast from '../Toast'

const ShareModal: React.FC = () => {
  const toast = useToast()
  const navigator = window.navigator as Navigator & {
    share: (data?: { title: string; text: string; url: string } | undefined) => Promise<void>
  }
  const shareLink = 'https://online.prophetic-channel.org'

  const handleCopyToClipboard = () => {
    toast({
      ...SUCCESS_TOAST,
      render: ({ onClose }) => (
        <Toast status="success" title="Share link copied to clipboard" onClose={onClose} />
      )
    })
  }

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Major 1 Connect',
          text: 'Check out Major 1 Connect',
          url: 'https://online.prophetic-channel.org/'
        })
        .then(() => logger('Successful share'))
        .catch((error) => logger('Error sharing', error))
    }
  }

  return (
    <React.Fragment>
      <ModalOverlay />
      <ModalContent shadow="none" bg="transparent" rounded="md">
        <Card
          rounded="16px"
          shadow="md"
          m={4}
          overflow="auto"
          borderWidth={0}
          justifyContent="flex-start"
          position="relative"
        >
          <ModalCloseButton
            backgroundColor="transparent"
            color="white"
            rounded="full"
            size="lg"
            top="12px"
          />
          <Flex
            alignItems="center"
            backgroundColor="solid.green"
            color="white"
            flexDirection="column"
            justifyContent="center"
            padding={8}
          >
            <Image src={images.majorOneLogoWhite} margin={8} size={80} />
            <Heading as="h2" fontSize="lg" marginY={2}>
              Grow our community
            </Heading>
            <Heading as="h3" fontSize="md" fontWeight={500} textAlign="center">
              Tell friends about Major 1 Connect
            </Heading>
          </Flex>
          <Box padding={4} width="100%">
            <CopyToClipboard text={shareLink} onCopy={handleCopyToClipboard}>
              <Flex
                alignItems="center"
                backgroundColor="green.50"
                justifyContent="space-between"
                marginBottom={4}
                padding={3}
                rounded="lg"
              >
                <Text
                  isTruncated
                  color="solid.green"
                  fontWeight={500}
                  marginX={2}
                  pointerEvents="none"
                >
                  {shareLink}
                </Text>
                <Link color={theme.colors.solid.green} size={20} />
              </Flex>
            </CopyToClipboard>

            <Flex>
              <CopyToClipboard text={shareLink} onCopy={handleCopyToClipboard}>
                <Button
                  boxShadow={theme.buttonShadow}
                  flex={1}
                  fontSize="sm"
                  marginRight={2}
                  rounded="lg"
                  size="lg"
                  variantColor="brand"
                >
                  Copy Link
                </Button>
              </CopyToClipboard>

              <Button
                flex={1}
                fontSize="sm"
                rounded="lg"
                size="lg"
                variant="outline"
                variantColor="brand"
                onClick={handleShare}
              >
                Send Link
              </Button>
            </Flex>
          </Box>
        </Card>
      </ModalContent>
    </React.Fragment>
  )
}

export default ShareModal
