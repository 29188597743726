import { throttle } from 'lodash'
import React, { useLayoutEffect } from 'react'

function useThrottledResize(ref: React.RefObject<HTMLElement>, delay?: 500) {
  const [dimensions, setDimensions] = React.useState<{
    width: number | null
    height: number | null
  }>({ width: null, height: null })
  const rafId = React.useRef<number>()

  useLayoutEffect(() => {
    if (!ref.current) return

    const node = ref.current

    function measure() {
      rafId.current = requestAnimationFrame(() => {
        setDimensions({ width: node.offsetWidth, height: node.offsetHeight })
      })
    }

    const throttledMeasure = throttle(measure, delay)

    window.addEventListener('resize', throttledMeasure)
    window.addEventListener('scroll', throttledMeasure)

    return () => {
      if (rafId.current) {
        cancelAnimationFrame(rafId.current)
      }
      window.removeEventListener('resize', throttledMeasure)
      window.removeEventListener('scroll', throttledMeasure)
    }
  }, [ref, delay])

  return dimensions
}

export default useThrottledResize
